<template>
	<div class="bc-full-x bc-full-y">
		<div class="bc-full-x bc-full-y" style="background: #000">
			<video style="width: 100%; height: 100%" autoplay controls :src='url' />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				url: '',
				myPlyer: ''
			}
		},
		mounted() {
			const { url } = this.$route.query
			this.url = url
		}
	}
</script>

<style lang="scss" scoped>
</style>
